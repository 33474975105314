import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { COLORS } from "src/layout/constants"
import { ImageColumn, Square, Top, Sheet, Specs, Bottom } from "src/components/styled"
import Spec from "src/components/Spec"
import Download from "src/svg/download.svg"
import Inspirations from "src/components/Inspirations"
import { intersec } from "src/helpers/utils"

const buttonCss = css<{ $selected: boolean }>`
  display: inline-block;
  border: 1px solid ${({ $selected }) => ($selected ? COLORS.black : COLORS.light)};
  margin: 0 20px 20px 0;
  padding: 4px;
  width: 60px;
  height: 60px;
`

const MaterialButton = styled.button<{ $selected: boolean }>`
  ${buttonCss}
  border-radius: 30px;
  .gatsby-image-wrapper {
    border-radius: 25px;
    overflow: hidden;
  }
`
const LiningButton = styled(Link)<{ $selected: boolean }>`
  ${buttonCss}
  .gatsby-image-wrapper {
    border: 1px solid ${COLORS.black};
  }
`
const FormatButton = styled.button<{ $selected: boolean }>`
  display: inline-block;
  border: 1px solid ${({ $selected }) => ($selected ? COLORS.black : COLORS.light)};
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 25px 40px 0;
`
const MoreInfos = styled.div`
  text-align: right;
  margin: 10px 0 20px;
  a {
    text-decoration: underline;
    font-size: 0.9em;
  }
`

interface DataType {
  materials: { nodes: Material[] }
  patterns: { nodes: Pattern[] }
}
interface ContextType {
  reference: string
}

const LiningPage: GatsbyPage<DataType, ContextType> = ({ data, pageContext }) => {
  const { getNodes } = useLang()
  const patterns = getNodes<Pattern>(data.patterns)
  const materials = getNodes<Material>(data.materials)
  const pattern = patterns.find(({ reference }) => reference === pageContext.reference)
  const [material, setMaterial] = useState(materials[0])
  const [format, setFormat] = useState(pattern.formats[0])

  const inspirations = intersec(pattern.inspirations, material.inspirations, "slug")

  return (
    <Layout subMenu="products" pageTitle={pattern.name}>
      <Top>
        <FormattedMessage id="products.liningTitle" tagName="h1" />
        <FormattedMessage id="lining.subtitle" tagName="h2" />
      </Top>
      <Sheet>
        <ImageColumn>
          <Square>
            <Image fluid={material.background.fluid} />
            <Image fluid={format.image.fluid} />
          </Square>
        </ImageColumn>
        <Specs>
          <div>
            <FormattedMessage id="lining.selectMaterial" tagName="h4" />
            {materials.map((current) => {
              const { reference, image, name } = current
              return (
                <MaterialButton
                  key={reference}
                  $selected={reference === material.reference}
                  onClick={() => {
                    setMaterial(current)
                  }}
                  title={name}
                >
                  <Image fixed={image.fixed} />
                </MaterialButton>
              )
            })}
            <FormattedMessage id="lining.selectPattern" tagName="h4" />
            {patterns.map(({ reference, image, name }) => {
              return (
                <LiningButton
                  key={reference}
                  $selected={reference === pattern.reference}
                  to={`/products/lining/${reference}`}
                  title={name}
                >
                  <Image fixed={image.fixed} />
                </LiningButton>
              )
            })}
            <FormattedMessage id="lining.selectFormat" tagName="h4" />
            {pattern.formats.map((current) => {
              const { letter, name } = current
              return (
                <FormatButton
                  key={letter}
                  $selected={letter === format.letter}
                  onClick={() => {
                    setFormat(current)
                  }}
                  title={name}
                >
                  {letter}
                </FormatButton>
              )
            })}
            <FormattedMessage id="lining.material" tagName="h4" />
            <Spec id="material.name" value={material.name} />
            <MoreInfos>
              <Link to={`/products/material/${material.reference}/`}>
                <FormattedMessage id="lining.infos" />
              </Link>
            </MoreInfos>
            <FormattedMessage id="lining.pattern" tagName="h4" />
            <Spec id="lining.name" value={pattern.name} />
            <Spec id="lining.reference" value={`${pattern.reference}.${format.letter}`} />
            <Spec id="lining.format" value={format.name} />
            <Spec id="lining.density" value={String(format.density)} suffix="lining.pieces" />
            <Spec id="lining.size" value={format.size} />
          </div>
          <Bottom>
            <Link to="/catalog/">
              <FormattedMessage id="lining.download" />
              <br />
              <Download />
            </Link>
          </Bottom>
        </Specs>
      </Sheet>
      <Inspirations boxed list={inspirations} />
    </Layout>
  )
}

export default LiningPage

export const query = graphql`
  query {
    patterns: allContentfulMotif(sort: { fields: position }) {
      nodes {
        node_locale
        reference
        name
        image {
          fixed(width: 50) {
            ...GatsbyContentfulFixed_noBase64
          }
        }
        formats {
          letter
          name
          size
          density
          image {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        inspirations {
          slug
          photo {
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    materials: allContentfulMatiere(sort: { fields: position }) {
      nodes {
        node_locale
        reference
        name
        image {
          fixed(width: 50) {
            ...GatsbyContentfulFixed
          }
        }
        background {
          fluid(maxWidth: 1600, quality: 75) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        inspirations {
          slug
          photo {
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
